var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.genericcareer", false, "Carrera Genérica"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.genericcareer',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_genericcareer'),expression:"'mesh.add_genericcareer'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'mesh.genericcareer',
            false,
            'Carrera Genérica'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-generic_career-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{attrs:{"items":_vm.genericCareers,"pagination":_vm.genericCareers.length,"fields":_vm.genericCareersFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(row.item.description)}})]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.genericcareer',
            false,
            'Carrera Genérica'
          )}`,"click_button":() => _vm.$bvModal.show(`edit-generic_career-modal-${row.item.id}`),"icon":'square'}}),_c('ButtonSmall',{attrs:{"click_button":() => _vm.askForDeleteGenericCareer(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.genericcareer',
            false,
            'Carrera Genérica'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-generic_career-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.genericcareer',
            false,
            'Carrera Genérica'
          )}`,"size":"md","hide-footer":""}},[_c('GenericCareerForm',{attrs:{"GenericCareer":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedGenericCareer}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-generic_career-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.genericcareer',
      false,
      'Carrera Genérica'
    )}`,"size":"md"}},[_c('GenericCareerForm',{on:{"created":_vm.slotCreatedGenericCareer}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }